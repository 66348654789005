// ---- Apply style to the root of the react project + root of the antd modal because it's outside of react root ----
#veesual_switch_model_root,
#veesual_switch_model_modal_root {
    $primary-antd: var(--scrollbar-color);
    background-color: white;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    h2 {
        margin-top: 8px;
        margin-bottom: 8px;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.1;
        height: auto;
    }

    .ant-image-preview-close {
        width: auto;
    }

    .ant-image-preview-operations-operation-rotateRight,
    .ant-image-preview-operations-operation-rotateLeft,
    .ant-image-preview-operations-operation-flipX,
    .ant-image-preview-operations-operation-flipY {
        display: none;
    }

    // ---- Modal Global Style ----
    .ant-modal {
        .ant-modal-close {
            width: auto;
            height: auto;
        }

        .ant-modal-close-x {
            line-height: normal;
        }
    }
    .ant-modal-content {
        box-shadow: none;
    }

    .ant-modal-root .ant-modal-mask {
        height: 200%;
    }

    // ---- Select Global Style ----
    .ant-select-selection-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .ant-select-single .ant-select-selector::after,
    .ant-select-single .ant-select-selector .ant-select-selection-item::after {
        display: none;
    }

    ::-webkit-scrollbar {
        /* Remove scrollbar space */
        width: 0;
        /* Optional: just make scrollbar invisible */
        background: transparent;
    }

    // ---- Remove weird animation from antd ----
    .ant-btn>div {
        display: none !important;
    }

    .custom-loader {
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}

#veesual_switch_model_modal_root {
    position: relative;
    z-index: 99999;
}