#veesual_switch_model_modal_root .modal,
#veesual_switch_model_root .modal {

    top: 0;
    width: 100vw !important;
    max-width: unset;
    height: auto !important;
    position: relative !important;

    @include breakpoints(sm) {
        width: auto !important;
    }

    &--header {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    &--container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;
        width: 100vw;
        height: calc(100vh - 32px);
        max-width: 1500px;
        max-height: 1000px;
        overflow: hidden;
        background-color: white;
        transition: opacity 0.2s 0.2s;

        @include breakpoints(sm) {
            width: 90vw;
        }

        @include breakpoints(md) {
            width: 80vw;
        }
    }

    &--content {
        display: flex;
        width: 100%;
        flex: 1;
        justify-content: center;
        align-items: center;
        overflow-y: auto;
        padding: 0;
    }

    &--close {
        position: absolute;
        right: 8px;

        :hover {
            background-color: $lightcolor;
            border-radius: 24px;
        }
    }

    &--loading-container {
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        z-index: 99999;
    }

    &--loader {
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.45);
    }

    .ant-modal,
    .ant-modal-content {
        margin: 0;
        padding: 0;
        border-radius: 0;
        top: 0;
        background-color: transparent;
    }

    .ant-modal-body {
        display: flex;
        padding: 0px;
        max-height: 100vh;
    }

    .ant-modal-title {
        text-align: center;
    }
}

// ---- Custom modal animation ----
@keyframes antFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes antFadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.ant-modal-fade-in-enter,
.ant-modal-fade-in-appear {
    opacity: 0;
    animation-play-state: paused;
    animation-duration: 0.20s;
    animation-fill-mode: both;
}

.ant-modal-fade-in-leave {
    animation-play-state: paused;
    animation-duration: 0.10s;
    animation-fill-mode: both;
}

.ant-modal-fade-in-enter.ant-modal-fade-in-enter-active,
.ant-modal-fade-in-appear.ant-modal-fade-in-appear-active {
    animation-name: antFadeIn;
    animation-play-state: running;
}

.ant-modal-fade-in-leave.ant-modal-fade-in-leave-active {
    animation-name: antFadeOut;
    animation-play-state: running;
    pointer-events: none;
}